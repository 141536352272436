import React, { FC, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { CareersPageType } from 'types';
import {
  PageLayout,
  PageHeading,
  CareerSummary,
  RenderVideoAndImage,
  FAQ,
  CareerContent,
} from 'components';

const Careers: FC<CareersPageType> = ({ data }: any) => {
  const {
    title,
    pageTheme,
    seo,
    banner,
    lifeWithUs: { title: lifeWithUsTitle, Video },
    faq: { title: faqTitle, tagline: faqTagline, faqs },
    getInTouch,
    imageContent,
    featuredJobPerks,
  } = data.CareersPage;
  const VideoGallery: any = [];
  const [careerQuizOpen, IscareerQuizOpen] = useState(false);

  useEffect(() => {
    VideoGallery.push(Video.video?.localFile.publicURL);
  }, [VideoGallery]);

  const handleBannerCta = () => {
    IscareerQuizOpen(true);
  };
  return (
    <PageLayout
      title="Careers"
      metaTitle={seo?.metaTitle ?? title}
      description={seo?.metaDescription}
      image={seo?.shareImage?.localFile?.publicURL}
      centerAlign
      type="Careers"
      className={`career ${pageTheme}`}
      bannerTitle={banner?.title}
      bannerTagline={banner?.tagline}
      bannerImage={banner?.image}
      bannerButton={banner?.ctaButton}
      bannerPlaceholder={banner?.postcodePlaceholder}
      bannerVideo={banner?.video}
      bannerButtonCtaHandler={handleBannerCta}
      faqs={faqs}
    >
      <div className="container">
        <div className="career-page-wrapper">
          <PageHeading title={title} level={1} />
          <CareerContent
            data={imageContent}
            featuredJobPerks={featuredJobPerks}
          />
        </div>
      </div>
      <CareerSummary
        careerDetails={getInTouch}
        careerQuizOpen={careerQuizOpen}
        IscareerQuizOpen={IscareerQuizOpen}
      />
      <section>
        <div className="container">
          <h4 className="career-title">{lifeWithUsTitle}</h4>
          <div className="career-video">
            <RenderVideoAndImage
              data={Video}
              altText={lifeWithUsTitle}
              hasPlayButton
              buttontype="icon"
              buttonsize="medium"
              videogallery={VideoGallery}
              showControls
              showModal
              showModalforMobile
            />
          </div>
        </div>
        <div className="container">
          <section className="home-page-faq-section">
            <PageHeading title={faqTitle} subtitle={faqTagline} />
            <FAQ Faqs={faqs} />
          </section>
        </div>
      </section>
    </PageLayout>
  );
};

export default Careers;

export const query = graphql`
  query CareersPageQuery {
    CareersPage: strapiCareersPage {
      title
      pageTheme
      banner {
        ctaButton
        id
        postcodePlaceholder
        tagline
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        video {
          localFile {
            publicURL
          }
        }
      }
      seo {
        metaDescription
        metaTitle
        shareImage {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 400, height: 400)
            }
          }
        }
      }
      faq {
        tagline
        title
        faqs {
          answer
          title
        }
      }
      getInTouch {
        heading
        description
        ctaLabel
        email
        jobPerks {
          id
          title
        }
      }
      lifeWithUs {
        title
        Video {
          image {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
              publicURL
            }
          }
          video {
            localFile {
              publicURL
            }
          }
        }
      }
      featuredJobPerks {
        id
        hasBg
        title
      }
      imageContent {
        title
        id
        description
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`;
